import { Link } from '@remix-run/react';

import { useUser } from '~/data/user/hooks/useUser';

import { CsrfTokenInput } from '~/helpers/auth/csrf/CsrfTokenInput';

import { Form } from '~/components/.shared/form/Form';

export function LoggedInMessage() {
  const user = useUser();

  return user ? (
    <>
      <Form action="/log-out" method="post" reloadDocument>
        <CsrfTokenInput />
        <p>
          You're currently logged in as <span className="font-semi-bold">{user.data.attributes.email}</span>.{' '}
          <button className="link">Not you?</button>
        </p>
      </Form>
      <Link to="/">Go to your account</Link>
    </>
  ) : null;
}
